import React from "react";
import { Link } from "react-router-dom";
import FontAwesome from "../uiStyle/FontAwesome";
import { convertFirebaseDate } from "../../utils/commonFunctions";

const InternationalNews = ({ title, showMore, className, data }) => {
  return (
    <div
      className={`business3 padding30 white_bg border-radious5 ${
        className ? className : ""
      }`}
    >
      {data.map((item, i) => (
        <div
          key={i}
          className={`single_post post_type12 type20 ${
            i + 1 < data.length ? "mb30" : ""
          }`}
        >
          <div className="post_img">
            <div className="img_wrap  border-radious5">
              <Link to="/post3">
                <img
                  src={item.img}
                  alt="thumb"
                  style={{
                    height: "200px",
                    width: "300px",
                  }}
                />
              </Link>
            </div>
          </div>
          <div
            className="single_post_text"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "200px",
            }}
          >
            <div>
              <h4>
                <Link to="/post3">{item.title}</Link>
              </h4>
              <div className="space-10" />
              <p
                className="post-p"
                dangerouslySetInnerHTML={{
                  __html:
                    item.description.length > 100
                      ? `${item.description.substring(0, 100)}...`
                      : item.description,
                }}
              />
              <div className="space-10" />
            </div>

            <div className="row">
              <div className="col-9 align-self-cnter">
                <div className="meta3">
                  <Link to="/post3">{item.category}</Link>
                  <Link to="/post3">{convertFirebaseDate(item.date)}</Link>
                </div>
              </div>

              <div className="col-3 align-self-cnter">
                <div className="share_meta4 text-right">
                  <ul className="inline">
                    <li>
                      <Link to="/">
                        <FontAwesome name="bookmark" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <FontAwesome name="share" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      {showMore ? (
        <Link to="/" className="showmore">
          Show more
        </Link>
      ) : null}
    </div>
  );
};

export default InternationalNews;
