import React, { Fragment, useState } from "react";
import FontAwesome from "../uiStyle/FontAwesome";

import { colors } from '../../utils/constants';

const MainMenuThree = ({ className }) => {
  return (
    <div
      style={{
        backgroundColor: "black",
        height: "60px",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        color: "white",
        padding: "0 70px",
      }}
    >
      <FontAwesome name="bars" style={{ fontSize: 20 }} />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          color: "white",
        }}
      >
        <div style={{ fontSize: 16, color: colors.defaultBg }}>Pior mês do Ibovespa em 20 anos</div>
        <div style={{ margin: '0 20px' }} >|</div>
        <div style={{ fontSize: 16 }}>As 3 maiores ações da NASDAQ</div>
        <div style={{ margin: '0 20px' }} >|</div>
        <div style={{ fontSize: 16 }}>Vencendo o medo de ivestir na Bolsa</div>
      </div>

      <FontAwesome name="search" style={{ fontSize: 20 }} />
    </div>
  );
};

export default MainMenuThree;
