import React, { Fragment, useEffect, useState } from "react";
import MainMenuThree from "../../components/MainMenuThree";
import HeroArea from "../../components/HeroArea";
import WidgetFinanceTwo from "../../components/WidgetFinanceTwo";
import { Link } from "react-router-dom";
import InternationalNews from "../../components/InternationalNews";

import cover from '../../doc/img/mock/cover.jpg'

import firebase from "../../utils/firebase";


const financePosts = [
  {
    photo: cover,
    title: "Copa America: Luis Suarez from devastated US",
    description:
      "The property, complete with seates screening from room amphitheater pond with sandy",
  },
  {
    photo: cover,
    title: "Copa America: Luis Suarez from devastated US",
    description:
      "The property, complete with seates screening from room amphitheater pond with sandy",
  },
];

const HomePageThree = () => {
  const [posts, setPosts] = useState(null);

  useEffect(() => {
    if (!posts) {
      firebase.db
        .collection("news")
        .get()
        .then((querySnapshot) => {
          const data = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setPosts(data);
        });
    }
  }, [posts]);

  return (
    <Fragment>
      <div className="wrapper_welcome">
        <MainMenuThree className="home4menu" />
        <HeroArea />
        <div className="bg4">
          <div className="space-60" />
          <div className="total3 mb30">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-xl-8">
                  <InternationalNews
                    data={posts || []}
                    className="mb30"
                    title={true}
                    showMore={false}
                  />
                </div>
                <div className="col-md-6 col-xl-4 d-md-none d-xl-block">
                  <WidgetFinanceTwo data={financePosts} title="Finance" />
                  
                </div>
              </div>
            </div>
          </div>
          <div className="space-60" />
        </div>
      </div>
    </Fragment>
  );
};

export default HomePageThree;
