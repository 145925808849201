import React from 'react';
import {Route, Switch} from 'react-router-dom';
import PrivateRoute from '../_PrivateRoute';
import NotFoundPage from "../NotFoundPage";
import HomePageThree from "../HomePageThree";
import BusinessThreePage from "../BusinessThreePage";
import EntertainmentThreePage from "../EntertainmentThreePage";
import FeatureThreePage from "../FeatureThreePage";
import SportsThreePage from "../SportsThreePage";
import TrendingThreePage from "../TrendingThreePage";
import AboutUsThreePage from "../AboutUsThreePage";
import ArchiveThreePage from "../ArchiveThreePage";
import ContactUsThreePage from "../ContactUsThreePage";
import NotFoundThreePage from "../NotFoundThreePage";
import PostOneHThreePage from "../PostOneHThreePage";
import PostTwoHThreePage from "../PostTwoHThreePage";
import PostThreeHThreePage from "../PostThreeHThreePage";
import VideoPostOneHThreePage from "../VideoPostOneHThreePage";
import VideoPostTwoHThreePage from "../VideoPostTwoHThreePage";
import VideoPostThreeHThreePage from "../VideoPostThreeHThreePage";
import AudioPostOneHThreePage from "../AudioPostOneHThreePage";
import AudioPostTwoHThreePage from "../AudioPostTwoHThreePage";
import AudioPostThreeHThreePage from "../AudioPostThreeHThreePage";
import PostOneHThreeLeftSidebarPage from "../PostOneHThreeLeftSidebarPage";
// import PublicRoute from '../_PublicRoute';

const Routes = () => {
    return (
        <Switch>
            {/*home page three*/}
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4"
                path="/"
                component={HomePageThree}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/business"
                component={BusinessThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/entertainment"
                component={EntertainmentThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/features"
                component={FeatureThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/sports"
                component={SportsThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/trending"
                component={TrendingThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/about"
                component={AboutUsThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/archive"
                component={ArchiveThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/contact"
                component={ContactUsThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/404"
                component={NotFoundThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4"
                path="/post1"
                component={PostOneHThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4"
                path="/post2"
                component={PostTwoHThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4 theme3_bg"
                path="/post3"
                component={PostThreeHThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4"
                path="/video_post1"
                component={VideoPostOneHThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4"
                path="/video_post2"
                component={VideoPostTwoHThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4"
                path="/video_post3"
                component={VideoPostThreeHThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4"
                path="/audio_post1"
                component={AudioPostOneHThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4"
                path="/audio_post2"
                component={AudioPostTwoHThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4"
                path="/audio_post3"
                component={AudioPostThreeHThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4"
                path="/left_post2"
                component={PostOneHThreeLeftSidebarPage}/>

            <Route exact component={NotFoundPage}/>
        </Switch>
    );
};
export default Routes