import React from "react";

import mock from "../../doc/img/mock/hero1.jpg";

import { colors } from "../../utils/constants";

const slider = [
  {
    photo: mock,
    category: "Business",
    date: "March 2020",
    title:
      "Setting politics aside, Sequoia raises $3.4 billion for US and China investments",
  },
];

const HeroArea = () => {
  return (
    <div className="wrapper_items">
      <div className="wrapper_carousel wlc_slider_demo2" >
        {slider.map((item, i) => (
          <div
            key={i}
            className="welcome4_area_wrap wlc_overlay"
            style={{
              background: `url(${item.photo}) center/cover no-repeat`,
              height: '650px'
            }}
          >
            <div className="welcome4_area">
              <div className="container">
                <div className="row">
                  <div className="col-lg-7">
                    <div className="welcome_txt">
                      <p className="title_meta">
                        {item.category} <span>| {item.date}</span>
                      </p>
                      <h1>{item.title}</h1>

                      <button
                        className="cbtn2"
                        style={{
                          position: "relative",
                          top: 50,
                          backgroundColor: colors.defaultBg,
                        }}
                      >
                        Botão 1
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HeroArea;
