import React, { useState } from "react";
import { Link } from "react-router-dom";
import FontAwesome from "../uiStyle/FontAwesome";

import Logo from "../../doc/img/logo/logo-themoney.png";
import { colors } from "../../utils/constants";

// Header
const LogoAreaThree = () => {
  return (
    <div
      style={{
        height: "60px",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        color: "white",
        padding: "0 70px",
      }}
    >
      <div style={{ fontSize: 16, color: colors.defaultBg }}>
        Assine nossa Newsletter {">"}
      </div>

      <img src={Logo} style={{ height: '40px' }} />

      <div className="social4 text-right">
        <ul className="inline">
          <li>
            <Link to="/">
              <FontAwesome name="twitter" />
            </Link>
          </li>
          <li>
            <Link to="/">
              <FontAwesome name="facebook-f" />
            </Link>
          </li>
          <li>
            <Link to="/">
              <FontAwesome name="youtube-play" />
            </Link>
          </li>
          <li>
            <Link to="/">
              <FontAwesome name="instagram" />
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default LogoAreaThree;
